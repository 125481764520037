import { AnalyticService, DataLayerType } from '@jsCore/src/types/services';

class GtmAnalyticService implements AnalyticService {
  sendEvent(dataLayer: DataLayerType) {
    window.dataLayer = window.dataLayer || [];

    const data = {
      content_type: 'NJOI',
      ...dataLayer
    };

    window.dataLayer.push(data);
  }
}

export default GtmAnalyticService;
