import { NjoiSupportCategory } from '@jsCore/src/types/providers';
import {
  SupportRepository,
  ConfigRepository
} from '@jsCore/src/types/repositories';

export const getSupportQuestionsUseCase = async (
  supportRepository: SupportRepository,
  configRepository: ConfigRepository
): Promise<Array<NjoiSupportCategory>> => {
  const language = configRepository.getLanguage();
  return await supportRepository.fetchData(language);
};
