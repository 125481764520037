import config from '@jsCore/src/config';
import {
  GreenFieldLeadInputs,
  GreenFieldUpdateLeadInputs,
  GreenFieldSearchInputs
} from '@jsCore/src/types/models/greenfield';
import {
  GenerateOTPInputs,
  VerifyOTPInputs
} from '@jsCore/src/types/models/otp';
import {
  AcmShopApiProvider,
  AcmCassandraApiProvider,
  NjoiApiProvider,
  LocalStorageProvider,
  GetSignedUrlInput,
  ConsentInput,
  RegisterInterestInput
} from '@jsCore/src/types/providers';
import { GreenFieldRepository } from '@jsCore/src/types/repositories';
import { EnvironmentService } from '@jsCore/src/types/services';

class DefaultGreenFieldRepository implements GreenFieldRepository {
  private acmShopApiProvider: AcmShopApiProvider;
  private acmCassandraApiProvider: AcmCassandraApiProvider;
  private njoiApiProvider: NjoiApiProvider;
  private storageProvider: LocalStorageProvider;
  private environmentService: EnvironmentService;

  constructor(
    cassandraLeadApiProvider: AcmShopApiProvider,
    cassandraServiceApiProvider: AcmCassandraApiProvider,
    sessionStorageProvider: LocalStorageProvider,
    environmentService: EnvironmentService,
    njoiApiProvider: NjoiApiProvider
  ) {
    this.acmShopApiProvider = cassandraLeadApiProvider;
    this.acmCassandraApiProvider = cassandraServiceApiProvider;
    this.storageProvider = sessionStorageProvider;
    this.environmentService = environmentService;
    this.njoiApiProvider = njoiApiProvider;
  }

  setLeadGeneratedToken(token: string) {
    this.storageProvider.setItem(config.cassandraLeadTokenName, token);
  }

  getLeadGeneratedToken(): string {
    const token = this.storageProvider.getItem(config.cassandraLeadTokenName);
    return token && typeof token === 'string' ? token : '';
  }

  async submitLead(input: GreenFieldLeadInputs) {
    const submitResponse = await this.acmShopApiProvider.generateLeadData(
      input
    );
    return submitResponse.data.response;
  }

  async updateLead(input: GreenFieldUpdateLeadInputs, token: string) {
    const response = await this.acmShopApiProvider.updateLeadData(input, token);
    return response.data.response;
  }

  async searchAddress(input: GreenFieldSearchInputs, token: string) {
    const searchResponse = await this.acmCassandraApiProvider.searchAddressData(
      input,
      token
    );
    return searchResponse.data.response;
  }

  async checkISPCoverage(input: GreenFieldSearchInputs, token: string) {
    const response = await this.acmCassandraApiProvider.checkISPCoverage(
      input,
      token
    );
    return response.data.response;
  }

  async getSignedUrl(input: GetSignedUrlInput, token: string) {
    const getSignedUrlResponse = await this.acmShopApiProvider.getSignedUrl(
      input,
      token
    );
    return getSignedUrlResponse.data.response;
  }

  async deleteSignedDocument(
    documentId: string,
    extension: string,
    token: string
  ) {
    const deleteDocResponse =
      await this.acmShopApiProvider.deleteSignedDocument(
        documentId,
        extension,
        token
      );

    return deleteDocResponse.data.response;
  }

  private isStaging(): boolean {
    return !this.environmentService.getEnvironment().isProduction();
  }

  async generateOTP(input: GenerateOTPInputs, token: string) {
    const response = await this.acmShopApiProvider.generateOTP(
      input,
      token,
      this.isStaging()
    );
    return response.data.response;
  }

  async verifyOtp(input: VerifyOTPInputs, token: string) {
    const response = await this.acmShopApiProvider.verifyOtp(
      input,
      token,
      this.isStaging()
    );
    return response.data.response;
  }

  async generateConsent(input: ConsentInput, token: string) {
    const response = await this.acmShopApiProvider.generateConsent(
      input,
      token
    );

    return response.data.response;
  }

  async registerInterest(input: RegisterInterestInput) {
    const response = await this.acmShopApiProvider.registerInterest(input);
    return response.data.response;
  }

  async getEntitledAstroFibreStatus(njoiToken: string) {
    const response = await this.njoiApiProvider.getEntitledAstroFibreStatus(
      njoiToken
    );
    return response.data.response;
  }
  async updateOrderAstroFibreDb(token: string) {
    const response = await this.njoiApiProvider.updateOrderAstroFibreDb(token);
    return response.data.response;
  }
}

export default DefaultGreenFieldRepository;
