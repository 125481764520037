import { ConsentInput } from '@jsCore/src/types/providers';
import { GreenFieldRepository } from '@jsCore/src/types/repositories';

export const generateConsentUseCase = async (
  greenFieldRepository: GreenFieldRepository,
  inputs: ConsentInput
) => {
  const token = greenFieldRepository.getLeadGeneratedToken();
  if (token === '') return Promise.reject('Token is unavailable');
  return await greenFieldRepository.generateConsent(inputs, token);
};
