import config, { LanguageTypeISO2 } from '@jsCore/src/config';
import {
  LocalStorageProvider,
  DigitalFortressApiProvider,
  DigitalFortressData
} from '@jsCore/src/types/providers';
import { ConfigRepository } from '@jsCore/src/types/repositories';

class DefaultConfigRepository implements ConfigRepository {
  private localStorageProvider: LocalStorageProvider;
  private digitalFortressApiProvider: DigitalFortressApiProvider;

  constructor(
    localStorageProvider: LocalStorageProvider,
    digitalFortressApiProvider: DigitalFortressApiProvider
  ) {
    this.localStorageProvider = localStorageProvider;
    this.digitalFortressApiProvider = digitalFortressApiProvider;
  }

  getLanguage(): LanguageTypeISO2 {
    const localDataLanguage = this.localStorageProvider.getItem(
      config.languageName
    ) as LanguageTypeISO2 | null;

    return localDataLanguage || config.defaultLanguage.iso;
  }

  setLanguage(language: LanguageTypeISO2) {
    this.localStorageProvider.setItem(config.languageName, language);
  }

  async getDigitalFortressData(): Promise<DigitalFortressData> {
    const response = await this.digitalFortressApiProvider.getHeaderData();
    return response.data.response;
  }
}

export default DefaultConfigRepository;
