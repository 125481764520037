import { LanguageTypeISO2 } from '@jsCore/src/config';
import {
  NjoiApiProvider,
  SearchProvider,
  NjoiSupportCategory,
  NjoiSupportArticle,
  NjoiSupportLink
} from '@jsCore/src/types/providers';
import { SupportRepository } from '@jsCore/src/types/repositories';
import { decode } from 'html-entities';

class DefaultSupportRepository implements SupportRepository {
  private njoiApiProvider: NjoiApiProvider;
  private searchProvider: SearchProvider;

  private supportCategories: Array<NjoiSupportCategory> = [];
  private supportQuestions: Array<NjoiSupportArticle> = [];

  constructor(
    njoiApiProvider: NjoiApiProvider,
    searchProvider: SearchProvider
  ) {
    this.njoiApiProvider = njoiApiProvider;
    this.searchProvider = searchProvider;
  }

  async fetchData(
    language: LanguageTypeISO2
  ): Promise<Array<NjoiSupportCategory>> {
    const response = await this.njoiApiProvider.getSupportData(language);
    this.supportCategories = response.data.response.category;
    this.supportQuestions = [];

    this.supportCategories.forEach((category) => {
      this.supportQuestions.push(
        ...category.questions.map((question) => ({
          ...question,
          id: question.ref_id,
          searchText: `${question.description} ${decode(
            question.content.replace(/<[^>]+>/g, '')
          )}`,
          slug: `${category.slug}/${question.slug}`,
          category: {
            // include category so web can display breadcrumbs
            name: category.name,
            slug: category.slug
          },
          relatedArticles: []
        }))
      );
    });

    this.searchProvider.init(this.supportQuestions, ['name', 'searchText']);

    return this.supportCategories;
  }

  search(searchText: string): Array<NjoiSupportArticle> {
    return this.searchProvider.search(searchText);
  }

  getCategoryDetail(slug: string): NjoiSupportCategory | undefined {
    return this.supportCategories.find((category) => category.slug === slug);
  }

  /**
   * @description get question by slug
   * @param {string} slug combination of category slug with question slug. eg: :category/:question
   * @returns {NjoiSupportArticle} return question detail with category. need to return category to display breadcrumbs
   */
  getQuestionDetail(slug: string): NjoiSupportArticle | undefined {
    const question = this.supportQuestions.find(
      (question) => question.slug === slug
    );

    if (question) {
      let questionIndex = 0;
      const relatedArticles: Array<NjoiSupportLink> = [];

      do {
        const item = this.supportQuestions[questionIndex];

        // compare slug so related articles will not repeat same question
        if (
          item.slug !== question.slug &&
          question.tags.filter((tag) => item.tags.includes(tag)).length > 0
        ) {
          relatedArticles.push({
            name: item.name,
            slug: item.slug
          });
        }

        questionIndex++;
      } while (
        relatedArticles.length < 5 &&
        questionIndex < this.supportQuestions.length
      );

      return {
        ...question,
        relatedArticles
      };
    } else {
      return undefined;
    }
  }
}

export default DefaultSupportRepository;
