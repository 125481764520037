import {
  ConfigRepository,
  ContentRepository
} from '@jsCore/src/types/repositories';
import { NjoiListing } from '@jsCore/src/types/providers';

export const getNjoiListingUseCase = async (
  configRepository: ConfigRepository,
  contentRepository: ContentRepository,
  link: string
): Promise<NjoiListing[]> => {
  const language = configRepository.getLanguage();
  return contentRepository.fetchNjoiListing(language, link);
};
