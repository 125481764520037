import config, { LanguageTypeISO2 } from '@jsCore/src/config';
import {
  NjoiApiProvider,
  LocalStorageProvider,
  NjoiAnnouncement,
  NjoiPromotion
} from '@jsCore/src/types/providers';
import { HighlightRepository } from '@jsCore/src/types/repositories';

class DefaultHighlightRepository implements HighlightRepository {
  private njoiApiProvider: NjoiApiProvider;
  private sessionStorageProvider: LocalStorageProvider;

  constructor(
    njoiApiProvider: NjoiApiProvider,
    sessionStorageProvider: LocalStorageProvider
  ) {
    this.njoiApiProvider = njoiApiProvider;
    this.sessionStorageProvider = sessionStorageProvider;
  }

  async fetchAnnouncement(
    language: LanguageTypeISO2
  ): Promise<NjoiAnnouncement> {
    const response = await this.njoiApiProvider.getAnnouncementData(language);

    const data = response?.data?.response;

    if (data) {
      return {
        ...data,
        isLightBackground: this.isLightColor(data.colourCode)
      };
    } else {
      return null;
    }
  }

  getCloseAnnouncement(): boolean {
    return this.getBooleanValueFromSession(config.announcementCloseSearchParam);
  }

  setCloseAnnouncement(closed: boolean): void {
    this.sessionStorageProvider.setItem(
      config.announcementCloseSearchParam,
      closed
    );
  }

  async fetchPromotion(language: LanguageTypeISO2): Promise<NjoiPromotion> {
    const response = await this.njoiApiProvider.getPromotionData(language);

    const data = response?.data?.response;

    if (data) {
      return {
        ...data,
        isLightBackground: this.isLightColor(data.colourCode)
      };
    } else {
      return null;
    }
  }

  getClosePromotion(): boolean {
    return this.getBooleanValueFromSession(config.promotionCloseSearchParam);
  }

  setClosePromotion(closed: boolean): void {
    this.sessionStorageProvider.setItem(
      config.promotionCloseSearchParam,
      closed
    );
  }

  private getBooleanValueFromSession(name: string): boolean {
    const booleanValue = this.sessionStorageProvider.getItem(name);

    return booleanValue !== null && typeof booleanValue === 'boolean'
      ? booleanValue
      : false;
  }

  isLightColor(hexCode: string): boolean {
    let color: any = hexCode;

    // Variables for red, green, blue values
    let r, g, b, hsp;

    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {
      // If RGB --> store the red, green, blue values in separate variables
      color = color.match(
        /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
      );

      r = color[1];
      g = color[2];
      b = color[3];
    } else {
      // If hex --> Convert it to RGB: http://gist.github.com/983661
      color = +(
        '0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&')
      );

      r = color >> 16;
      g = (color >> 8) & 255;
      b = color & 255;
    }

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

    // Using the HSP value, determine whether the color is light or dark
    return hsp > 127.5;
  }
}

export default DefaultHighlightRepository;
