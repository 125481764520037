import { LanguageTypeISO2 } from '@jsCore/src/config';
import {
  NjoiApiProvider,
  GetNjoiContent,
  NjoiCombo,
  NjoiDistributor,
  NjoiStoreArticleDetail,
  ChannelDetail,
  PackageDetail,
  BundleDetail,
  MovieDetail,
  SearchResult,
  NjoiListing,
  AddOnPack,
  MovieListingItem,
  ChannelListingItem,
  B40Token,
  B40Status,
  NjoiCampaign
} from '@jsCore/src/types/providers';
import { ContentRepository } from '@jsCore/src/types/repositories';

class DefaultContentRepository implements ContentRepository {
  private njoiApiProvider: NjoiApiProvider;

  constructor(njoiApiProvider: NjoiApiProvider) {
    this.njoiApiProvider = njoiApiProvider;
  }

  async fetchGetNjoiContent(
    language: LanguageTypeISO2
  ): Promise<GetNjoiContent> {
    const response = await this.njoiApiProvider.getGetNjoiContentData(language);
    return response.data.response;
  }

  async fetchNjoiCombo(language: LanguageTypeISO2): Promise<NjoiCombo> {
    const response = await this.njoiApiProvider.getNjoiComboData(language);
    return response.data.response;
  }

  async fetchNjoiCampaign(language: LanguageTypeISO2): Promise<NjoiCampaign> {
    const response = await this.njoiApiProvider.getNjoiCampaign(language);
    return response.data.response;
  }

  async fetchNjoiDistributor(state: string): Promise<NjoiDistributor[]> {
    const response = await this.njoiApiProvider.getNjoiDistributorData(state);
    return response.data.response;
  }

  async fetchNjoiStoreArticleDetail(
    language: LanguageTypeISO2,
    content: string
  ): Promise<NjoiStoreArticleDetail> {
    const response = await this.njoiApiProvider.getNjoiArticleDetailData(
      language,
      content
    );
    return response.data.response;
  }

  async fetchChannelDetail(
    language: LanguageTypeISO2,
    slug: string
  ): Promise<ChannelDetail> {
    const response = await this.njoiApiProvider.getChannelDetail(
      language,
      slug
    );
    return response.data.response;
  }

  async fetchMovieDetail(
    language: LanguageTypeISO2,
    slug: string
  ): Promise<MovieDetail> {
    const response = await this.njoiApiProvider.getMovieDetail(language, slug);
    return response.data.response;
  }

  async fetchPackageDetail(
    language: LanguageTypeISO2,
    slug: string
  ): Promise<PackageDetail> {
    const response = await this.njoiApiProvider.getPackageDetail(
      language,
      slug
    );
    return response.data.response;
  }

  async fetchBundleDetail(
    language: LanguageTypeISO2,
    slug: string
  ): Promise<BundleDetail> {
    const response = await this.njoiApiProvider.getBundleDetail(language, slug);
    return response.data.response;
  }

  async fetchSearchResult(
    language: LanguageTypeISO2,
    keyword: string
  ): Promise<SearchResult> {
    const response = await this.njoiApiProvider.getSearch(language, keyword);
    return response.data.response;
  }

  async fetchNjoiListing(
    language: LanguageTypeISO2,
    link: string
  ): Promise<NjoiListing[]> {
    const response = await this.njoiApiProvider.getNjoiListing(language, link);
    return response.data.response;
  }

  async fetchAddonPackListing(
    language: LanguageTypeISO2
  ): Promise<AddOnPack[]> {
    const response = await this.njoiApiProvider.getAddonPackListing(language);
    return response.data.response;
  }

  async fetchMovieListing(
    language: LanguageTypeISO2
  ): Promise<MovieListingItem[]> {
    const response = await this.njoiApiProvider.getMovieListing(language);
    return response.data.response;
  }

  async fetchChannelListing(
    language: LanguageTypeISO2
  ): Promise<ChannelListingItem[]> {
    const response = await this.njoiApiProvider.getChannelListing(language);
    return response.data.response;
  }

  async fetchB40Token(): Promise<B40Token> {
    const response = await this.njoiApiProvider.getB40Token();
    return response.data.response;
  }

  async fetchB40Status(
    identityCard: string,
    token: string,
    type: string
  ): Promise<B40Status> {
    const response = await this.njoiApiProvider.verifyB40Status(
      identityCard,
      token,
      type
    );
    return response.data.response;
  }
}

export default DefaultContentRepository;
