import { SearchClient } from '@jsCore/src/types/clients';
import LunrSearch from 'lunr';

class LunrSearchClient implements SearchClient {
  // @ts-ignore
  private lunr: LunrSearch.Index;

  // @ts-ignore
  private data: Map<string, any>;

  /**
   * @description must init lunr before use the search function
   * @param {Array<any>} data an `id` must include in data array
   * @param {Array<string>} keys
   */
  init(data: Array<any>, keys: Array<string>) {
    this.data = new Map();

    data.forEach((item) => {
      this.data.set(item.id, item);
    });

    this.lunr = LunrSearch(function () {
      keys.forEach((key) => {
        this.field(key);
      });

      data.forEach((item) => {
        this.add(item);
      });
    });
  }

  /**
   * *${searchText}* = wild card of the search text
   * ${searchText} = exact keyword to search
   * @param {string} searchText keywords to search from array
   * @returns {Array<any>}
   */
  search(searchText: string) {
    try {
      const searchResult = this.lunr.search(`*${searchText}* ${searchText}`);

      return searchResult.reduce((acc: Array<any>, curr: any) => {
        const item = this.data.get(curr.ref);
        acc.push(item);
        return acc;
      }, []);
    } catch (error) {
      console.error('Please run init() first before use search');
      return [];
    }
  }
}

export default LunrSearchClient;
