import { Environment } from '@jsCore/src/types/models/environment';
import { EnvironmentService } from '@jsCore/src/types/services';

class DefaultEnvironmentService implements EnvironmentService {
  private readonly environment: Environment;

  constructor(environment: Environment) {
    this.environment = environment;
  }

  getEnvironment(): Environment {
    return this.environment;
  }
}

export default DefaultEnvironmentService;
