import { HttpClient } from '@jsCore/src/types/clients';
import {
  GreenFieldLeadInputs,
  GreenFieldUpdateLeadInputs
} from '@jsCore/src/types/models/greenfield';
import {
  GenerateOTPInputs,
  VerifyOTPInputs
} from '@jsCore/src/types/models/otp';
import {
  AcmShopApiProvider,
  RegisterInterestInput,
  GetSignedUrlInput,
  ConsentInput
} from '@jsCore/src/types/providers';
import { EnvironmentService } from '@jsCore/src/types/services';

class DefaultAcmShopApiProvider implements AcmShopApiProvider {
  private httpClient: HttpClient;
  private environmentService: EnvironmentService;

  constructor(httpClient: HttpClient, environmentService: EnvironmentService) {
    this.httpClient = httpClient;
    this.environmentService = environmentService;
  }

  private getBaseUrl() {
    return this.environmentService.getEnvironment().getAcmShopApiBaseUrl();
  }

  generateLeadJsonData(input: GreenFieldLeadInputs): string {
    return JSON.stringify(input);
  }

  generateUpdateLeadJsonData(input: GreenFieldUpdateLeadInputs): string {
    return JSON.stringify(input);
  }

  generateRegisterInterestJsonData(input: RegisterInterestInput): string {
    return JSON.stringify(input);
  }

  generateOTPJsonData(input: GenerateOTPInputs): string {
    const otpData: {
      phoneNumber?: string;
      mock?: boolean;
    } = {};

    if (input.phoneNumber) {
      otpData.phoneNumber = input.phoneNumber;
    }

    if (input.mock !== undefined) {
      otpData.mock = input.mock;
    }

    return JSON.stringify(otpData);
  }

  generateVerifyOtpJsonData(input: VerifyOTPInputs): string {
    const verifyOtpData: {
      otp?: string;
      phoneNumber?: string;
      code?: string;
      mock?: boolean;
    } = {};

    if (input.phoneNumber) {
      verifyOtpData.phoneNumber = input.phoneNumber;
    }

    if (input.otp) {
      verifyOtpData.otp = input.otp;
    }

    if (input.mock !== undefined) {
      verifyOtpData.mock = input.mock;
    }

    if (input.code) {
      verifyOtpData.code = input.code;
    }

    return JSON.stringify(verifyOtpData);
  }

  generateSignedUrlJsonData(input: GetSignedUrlInput): string {
    return JSON.stringify(input);
  }

  generateConsentJsonData(input: ConsentInput): string {
    const consentData = {
      language: input.language
    };

    return JSON.stringify(consentData);
  }

  async generateLeadData(input: GreenFieldLeadInputs) {
    return this.httpClient.post(
      `${this.getBaseUrl()}/api/v1/greenfield/generate-sbb-lead`,
      this.generateLeadJsonData(input),
      { credentials: false }
    );
  }

  async updateLeadData(input: GreenFieldUpdateLeadInputs, token: string) {
    return this.httpClient.patch(
      `${this.getBaseUrl()}/api/v1/greenfield/update-sbb-lead`,
      this.generateUpdateLeadJsonData(input),
      { credentials: false, token }
    );
  }

  async getSignedUrl(input: GetSignedUrlInput, token: string) {
    return this.httpClient.post(
      `${this.getBaseUrl()}/api/v3/generate-signed-url`,
      this.generateSignedUrlJsonData(input),
      {
        credentials: false,
        token
      }
    );
  }

  async deleteSignedDocument(
    documentId: string,
    extension: string,
    token: string
  ) {
    return this.httpClient.delete(
      `${this.getBaseUrl()}/api/v3/document/{${documentId}}.{${extension}}`,
      { credentials: false, token }
    );
  }

  async generateOTP(input: GenerateOTPInputs, token: string, mock: boolean) {
    const newInput = {
      ...input,
      mock
    };
    return this.httpClient.post(
      `${this.getBaseUrl()}/api/v3/generate-otp`,
      this.generateOTPJsonData(newInput),
      { credentials: false, token }
    );
  }

  async verifyOtp(input: VerifyOTPInputs, token: string, mock: boolean) {
    const newInput = {
      ...input,
      mock
    };
    return this.httpClient.post(
      `${this.getBaseUrl()}/api/v3/verify-otp`,
      this.generateVerifyOtpJsonData(newInput),
      {
        credentials: false,
        token
      }
    );
  }

  async generateConsent(input: ConsentInput, token: string) {
    return this.httpClient.post(
      `${this.getBaseUrl()}/api/v3/consent`,
      this.generateConsentJsonData(input),
      { credentials: false, token }
    );
  }

  async registerInterest(input: RegisterInterestInput) {
    return this.httpClient.post(
      `${this.getBaseUrl()}/api/v3/register-interest`,
      this.generateRegisterInterestJsonData(input),
      { credentials: false }
    );
  }
}

export default DefaultAcmShopApiProvider;
