import {
  ConfigRepository,
  ContentRepository
} from '@jsCore/src/types/repositories';
import { SearchResult } from '@jsCore/src/types/providers';

export const getSearchResultUseCase = (
  configRepository: ConfigRepository,
  keyword: string,
  contentRepository: ContentRepository
): Promise<SearchResult> => {
  const language = configRepository.getLanguage();
  return contentRepository.fetchSearchResult(language, keyword);
};
