import { HttpClient } from '@jsCore/src/types/clients';
import { ContactUsInputs } from '@jsCore/src/types/models/contactus';
import { NdsApiProvider } from '@jsCore/src/types/providers';
import { EnvironmentService } from '@jsCore/src/types/services';

class DefaultNdsApiProvider implements NdsApiProvider {
  public httpClient: HttpClient;
  public environmentService: EnvironmentService;

  constructor(httpClient: HttpClient, environmentService: EnvironmentService) {
    this.httpClient = httpClient;
    this.environmentService = environmentService;
  }

  private getBaseURL() {
    return this.environmentService.getEnvironment().getNdsApiBaseURL();
  }

  private getAPIKey() {
    return this.environmentService.getEnvironment().getNdsApiKey();
  }

  private getAPIPathURL() {
    return this.environmentService.getEnvironment().getContactUsAPIPath();
  }

  generateLeadFormData(inputs: ContactUsInputs): FormData {
    const { fullname, mobileNumber, email, altNumber, address } = inputs;
    const contactUsFormData = new FormData();
    contactUsFormData.append('formKey', this.getAPIKey());
    contactUsFormData.append('name', fullname);
    contactUsFormData.append('first_contact_number', mobileNumber);
    contactUsFormData.append('email', email);
    altNumber && contactUsFormData.append('second_contact_number', altNumber);
    address && contactUsFormData.append('address', address);
    contactUsFormData.append('contact', 'Yes');
    return contactUsFormData;
  }

  async submitLeadData(inputs: ContactUsInputs) {
    return this.httpClient.post(
      `${this.getBaseURL()}/${this.getAPIPathURL()}`,
      this.generateLeadFormData(inputs),
      { credentials: false }
    );
  }
}

export default DefaultNdsApiProvider;
