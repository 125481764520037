import { NjoiSupportArticle } from '@jsCore/src/types/providers';
import { SupportRepository } from '@jsCore/src/types/repositories';

export const trimSearchText = (text: string): string => {
  return text.trim().replace(/\s\s+/g, ' ');
};

export const searchSupportQuestionsUseCase = (
  supportRepository: SupportRepository,
  searchText: string
): Array<NjoiSupportArticle> => {
  // trim white space for both end
  // replace multiple spaces to single space in between searchText
  const trimmedText = trimSearchText(searchText);
  return supportRepository.search(trimmedText);
};
