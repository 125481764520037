export const validateImageMetaUseCase = (
  type: string,
  size: number
): boolean => {
  const validImageTypes = [
    'image/png',
    'image/bmp',
    'image/jpg',
    'image/jpeg',
    'application.pdf'
  ];
  return validImageTypes.includes(type) && size <= 5000000 && size > 0;
};
