import { GreenFieldLeadInputs } from '@jsCore/src/types/models/greenfield';
import { GreenFieldRepository } from '@jsCore/src/types/repositories';

export const generateLeadUseCase = async (
  greenFieldRepository: GreenFieldRepository,
  inputs: GreenFieldLeadInputs
) => {
  const leadData = await greenFieldRepository.submitLead(inputs);
  const { leadId, token } = leadData;
  greenFieldRepository.setLeadGeneratedToken(token);
  return leadId;
};
