import { NjoiCampaign } from '@jsCore/src/types/providers';
import {
  ConfigRepository,
  ContentRepository
} from '@jsCore/src/types/repositories';

export const getNjoiCampaignUseCase = async (
  configRepository: ConfigRepository,
  contentRepository: ContentRepository
): Promise<NjoiCampaign> => {
  const language = configRepository.getLanguage();
  return contentRepository.fetchNjoiCampaign(language);
};
