import { HttpClient } from '@jsCore/src/types/clients';
import { UploadProvider } from '@jsCore/src/types/providers';

class DefaultUploadProvider implements UploadProvider {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async uploadFile(url: string, file: File) {
    return await this.httpClient.put(url, file, {
      Content_Type: file.type,
      credentials: false
    });
  }
}

export default DefaultUploadProvider;
