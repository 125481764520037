import { GenerateOTPInputs } from '@jsCore/src/types/models/otp';
import { GreenFieldRepository } from '@jsCore/src/types/repositories';

export const generateOTPUseCase = async (
  greenfieldRepository: GreenFieldRepository,
  inputs: GenerateOTPInputs
) => {
  const token = greenfieldRepository.getLeadGeneratedToken();
  if (token === '') return Promise.reject('Token is unavailable');
  const codeResponse = await greenfieldRepository.generateOTP(inputs, token);
  return codeResponse.code;
};
