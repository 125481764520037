import {
  RetrieveErrorResponse,
  RetrieveErrorResponseCode
} from '@jsCore/src/types/useCases';

export const retrieveErrorUseCase = (
  response: RetrieveErrorResponse
): RetrieveErrorResponseCode => {
  //SOMETIMES ASYNC THUNK CAN RETURN
  //UNDEFINED SO TO HANDLE THAT PUT
  //EXTRA CONDITION
  if (!response) return 'ASTRO_FIBRE_INVALID_TOKEN';

  const [{ errorCode }] = response.errors;

  if (!errorCode) {
    return 'ASTRO_FIBRE_INVALID_TOKEN';
  }

  if (
    errorCode &&
    (errorCode === 'AWS_DYNAMODB_GET_FAILED' || errorCode === 'SMC_MANDATORY')
  ) {
    return 'ASTRO_FIBRE_INVALID_TOKEN';
  }

  return errorCode;
};
