import { SearchClient } from '@jsCore/src/types/clients';
import { SearchProvider } from '@jsCore/src/types/providers';

class DefaultSearchProvider implements SearchProvider {
  private searchClient: SearchClient;

  constructor(searchClient: SearchClient) {
    this.searchClient = searchClient;
  }

  init(data: Array<any>, keys: Array<string>) {
    this.searchClient.init(data, keys);
  }

  search(searchText: string) {
    return this.searchClient.search(searchText);
  }
}

export default DefaultSearchProvider;
