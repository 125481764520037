import { GreenFieldRepository } from '@jsCore/src/types/repositories';

export const deleteSignedDocUseCase = async (
  greenFieldRepository: GreenFieldRepository,
  documentId: string,
  extension: string
) => {
  const token = greenFieldRepository.getLeadGeneratedToken();
  if (token === '') return Promise.reject('Token is unavailable');
  return await greenFieldRepository.deleteSignedDocument(
    documentId,
    extension,
    token
  );
};
