import { UploadProvider, UploadFileInput } from '@jsCore/src/types/providers';
import { UploadRepository } from '@jsCore/src/types/repositories';

class DefaultUploadRepository implements UploadRepository {
  private uploadProvider: UploadProvider;

  constructor(uploadProvider: UploadProvider) {
    this.uploadProvider = uploadProvider;
  }

  async uploadFile(input: UploadFileInput) {
    await this.uploadProvider.uploadFile(input.url, input.file!);
    return input.documentId;
  }
}

export default DefaultUploadRepository;
