import { GreenFieldSearchInputs } from '@jsCore/src/types/models/greenfield';
import { GreenFieldRepository } from '@jsCore/src/types/repositories';

export const checkISPCoverageUseCase = async (
  greenfieldRepository: GreenFieldRepository,
  inputs: GreenFieldSearchInputs
) => {
  const token = greenfieldRepository.getLeadGeneratedToken();
  if (token === '') return Promise.reject('Token is unavailable');
  return await greenfieldRepository.checkISPCoverage(inputs, token);
};
