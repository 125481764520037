import { GreenFieldUpdateLeadInputs } from '@jsCore/src/types/models/greenfield';
import { GreenFieldRepository } from '@jsCore/src/types/repositories';

export const updateLeadUseCase = async (
  greenFieldRepository: GreenFieldRepository,
  inputs: GreenFieldUpdateLeadInputs
) => {
  const token = greenFieldRepository.getLeadGeneratedToken();
  if (token === '') return Promise.reject('Token is unavailable');
  const leadData = await greenFieldRepository.updateLead(inputs, token);
  return leadData.leadId;
};
