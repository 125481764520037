import { UploadLeadAttachmentInput } from '@jsCore/src/types/providers';
import {
  UploadRepository,
  GreenFieldRepository
} from '@jsCore/src/types/repositories';

export const uploadLeadAttachmentUseCase = async (
  uploadRepository: UploadRepository,
  greenFieldRepository: GreenFieldRepository,
  inputs: UploadLeadAttachmentInput
) => {
  const token = greenFieldRepository.getLeadGeneratedToken();
  const signedUrl = await greenFieldRepository.getSignedUrl(
    { title: inputs.title, type: inputs.type },
    token
  );

  return uploadRepository.uploadFile({
    url: signedUrl.signedUploadUrl,
    file: inputs.file,
    documentId: signedUrl.documentId
  });
};
