import {
  ConfigRepository,
  ContentRepository
} from '@jsCore/src/types/repositories';
import { MovieDetail } from '@jsCore/src/types/providers';

export const getMovieDetailUseCase = (
  configRepository: ConfigRepository,
  slug: string,
  contentRepository: ContentRepository
): Promise<MovieDetail> => {
  const language = configRepository.getLanguage();
  return contentRepository.fetchMovieDetail(language, slug);
};
