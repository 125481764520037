import {
  ConfigRepository,
  ContentRepository
} from '@jsCore/src/types/repositories';
import { ChannelDetail } from '@jsCore/src/types/providers';

export const getChannelDetailUseCase = (
  configRepository: ConfigRepository,
  slug: string,
  contentRepository: ContentRepository
): Promise<ChannelDetail> => {
  const language = configRepository.getLanguage();
  return contentRepository.fetchChannelDetail(language, slug);
};
