import { HttpClient } from '@jsCore/src/types/clients';
import { GreenFieldSearchInputs } from '@jsCore/src/types/models/greenfield';
import { AcmCassandraApiProvider } from '@jsCore/src/types/providers';
import { EnvironmentService } from '@jsCore/src/types/services';

class DefaultAcmCassandraApiProvider implements AcmCassandraApiProvider {
  public httpClient: HttpClient;
  public environmentService: EnvironmentService;

  constructor(httpClient: HttpClient, environmentService: EnvironmentService) {
    this.httpClient = httpClient;
    this.environmentService = environmentService;
  }

  private getBaseUrl() {
    return this.environmentService.getEnvironment().getAcmCassandraApiBaseUrl();
  }

  generateSearchJsonData(input: GreenFieldSearchInputs): string {
    const searchData: {
      residenceType?: 1 | 2;
      postalCode?: string;
      streetType?: string | null;
      streetName?: string | null;
      houseNumber?: string | null;
      buildingName?: string | null;
      area?: string | null;
      city?: string | null;
      state?: string | null;
    } = {};

    searchData.postalCode = input.postalCode;

    if (input.residenceType === 'landed') {
      searchData.residenceType = 1;
    }

    if (input.residenceType === 'multiStorey') {
      searchData.residenceType = 2;
    }

    if (input.streetType) {
      searchData.streetType = input.streetType;
    }

    if (input.streetName) {
      searchData.streetName = input.streetName;
    }

    if (input.houseNumber) {
      searchData.houseNumber = input.houseNumber;
    }

    if (input.buildingName) {
      searchData.buildingName = input.buildingName;
    }

    if (input.area) {
      searchData.area = input.area;
    }

    if (input.city) {
      searchData.city = input.city;
    }

    if (input.state) {
      searchData.state = input.state;
    }

    return JSON.stringify(searchData);
  }

  async searchAddressData(input: GreenFieldSearchInputs, token: string) {
    return this.httpClient.post(
      `${this.getBaseUrl()}/cassandra/api/v1/address/greenfield-search`,
      this.generateSearchJsonData(input),
      { credentials: false, token: token }
    );
  }

  async checkISPCoverage(input: GreenFieldSearchInputs, token: string) {
    return this.httpClient.post(
      `${this.getBaseUrl()}/cassandra/api/v1/address/check-isp-coverage?platform=njoi`,
      this.generateSearchJsonData(input),
      { credentials: false, token: token }
    );
  }
}

export default DefaultAcmCassandraApiProvider;
