import { HttpClient } from '@jsCore/src/types/clients';
import { DigitalFortressApiProvider } from '@jsCore/src/types/providers';
import { EnvironmentService } from '@jsCore/src/types/services';

class DefaultDigitalFortressApiProvider implements DigitalFortressApiProvider {
  private httpClient: HttpClient;
  private environmentService: EnvironmentService;

  constructor(httpClient: HttpClient, environmentService: EnvironmentService) {
    this.httpClient = httpClient;
    this.environmentService = environmentService;
  }

  private getBaseUrl(): string {
    return this.environmentService.getEnvironment().getDigitalFortressBaseUrl();
  }

  async getHeaderData() {
    return await this.httpClient.get(this.getBaseUrl(), null, {
      credentials: false
    });
  }
}

export default DefaultDigitalFortressApiProvider;
