import { ContactUsInputs } from '@jsCore/src/types/models/contactus';
import { NdsApiProvider } from '@jsCore/src/types/providers';
import { PurchaseRepository } from '@jsCore/src/types/repositories';

class DefaultPurchaseRepository implements PurchaseRepository {
  private ndsApiProvider: NdsApiProvider;
  constructor(ndsApiProvider: NdsApiProvider) {
    this.ndsApiProvider = ndsApiProvider;
  }

  async submitLead(inputs: ContactUsInputs): Promise<string> {
    const formResponse = await this.ndsApiProvider.submitLeadData(inputs);
    return formResponse.data;
  }
}

export default DefaultPurchaseRepository;
