import config, { LanguageTypeISO2 } from '@jsCore/src/config';
import { HttpClient } from '@jsCore/src/types/clients';
import { NjoiApiProvider } from '@jsCore/src/types/providers';
import { EnvironmentService } from '@jsCore/src/types/services';

class DefaultNjoiApiProvider implements NjoiApiProvider {
  private httpClient: HttpClient;
  private environmentService: EnvironmentService;

  constructor(httpClient: HttpClient, environmentService: EnvironmentService) {
    this.httpClient = httpClient;
    this.environmentService = environmentService;
  }

  private getBaseUrl(): string {
    return this.environmentService.getEnvironment().getNjoiApiBaseUrl();
  }

  async authenticate(body: { smc: string; deviceId: string }) {
    return await this.httpClient.post(
      `${this.getBaseUrl()}/api/v1/authenticate`,
      body
    );
  }

  async getAnnouncementData(language: LanguageTypeISO2) {
    return await this.httpClient.get(
      `${this.getBaseUrl()}/api/v1/static/highlight/${language}/announcement`,
      null,
      { credentials: false }
    );
  }

  async getPromotionData(language: LanguageTypeISO2) {
    return await this.httpClient.get(
      `${this.getBaseUrl()}/api/v1/static/highlight/${language}/promotion`,
      null,
      { credentials: false }
    );
  }

  async getSupportData(language: LanguageTypeISO2) {
    return await this.httpClient.get(
      `${this.getBaseUrl()}/api/v1/static/support/${language}/all`,
      null,
      { credentials: false }
    );
  }

  async getGetNjoiContentData(language: LanguageTypeISO2) {
    return await this.httpClient.get(
      `${this.getBaseUrl()}/api/v1/static/content/${language}/get-njoi`,
      null,
      { credentials: false }
    );
  }

  async getNjoiComboData(language: LanguageTypeISO2) {
    return await this.httpClient.get(
      `${this.getBaseUrl()}/api/v1/static/content/${language}/get-njoi-combo-list`,
      null,
      { credentials: false }
    );
  }

  async getNjoiCampaign(language: LanguageTypeISO2) {
    return await this.httpClient.get(
      `${this.getBaseUrl()}/api/v1/static/content/${language}/campaign`,
      null,
      { credentials: false }
    );
  }

  async getNjoiDistributorData(state: string) {
    return await this.httpClient.get(
      `${this.getBaseUrl()}/api/v1/static/dealer/state/${state}`,
      null,
      { credentials: false }
    );
  }

  async getNjoiArticleDetailData(language: LanguageTypeISO2, slug: string) {
    return await this.httpClient.get(
      `${this.getBaseUrl()}/api/v1/static/content/${language}/get-njoi/${slug}`,
      null,
      { credentials: false }
    );
  }

  async getChannelDetail(language: LanguageTypeISO2, slug: string) {
    return await this.httpClient.get(
      `${this.getBaseUrl()}/api/v1/channel/${language}/${slug}`,
      null,
      { credentials: false }
    );
  }

  async getMovieDetail(language: LanguageTypeISO2, slug: string) {
    return await this.httpClient.get(
      `${this.getBaseUrl()}/api/v1/static/movie/${language}/${slug}`,
      null,
      { credentials: false }
    );
  }

  async getBundleDetail(language: LanguageTypeISO2, slug: string) {
    return await this.httpClient.get(
      `${this.getBaseUrl()}/api/v1/bundle/${language}/${slug}`,
      null,
      { credentials: false }
    );
  }

  async getPackageDetail(language: LanguageTypeISO2, slug: string) {
    return await this.httpClient.get(
      `${this.getBaseUrl()}/api/v1/package/${language}/${slug}`,
      null,
      { credentials: false }
    );
  }

  async getEntitledAstroFibreStatus(njoitoken: string) {
    return await this.httpClient.get(
      `${this.getBaseUrl()}/api/v1/entitled-astro-fibre`,
      null,
      { credentials: false, token: njoitoken }
    );
  }
  async updateOrderAstroFibreDb(token: string) {
    return await this.httpClient.post(
      `${this.getBaseUrl()}/api/v1/entitled-astro-fibre`,
      '',
      {
        credentials: false,
        token: token
      }
    );
  }

  async getSearch(language: LanguageTypeISO2, keyword: string) {
    return await this.httpClient.get(
      `${this.getBaseUrl()}/api/v1/search?keyword=${keyword}&lang=${language}`,
      null,
      { credentials: false }
    );
  }

  async getNjoiListing(language: LanguageTypeISO2, link: string) {
    return await this.httpClient.get(`${link}?lang=${language}`, null, {
      credentials: false
    });
  }

  async getAddonPackListing(language: LanguageTypeISO2) {
    return await this.httpClient.get(
      `${this.getBaseUrl()}/api/v1/listing/package?lang=${language}`,
      null,
      { credentials: false }
    );
  }

  async getMovieListing(language: LanguageTypeISO2) {
    return await this.httpClient.get(
      `${this.getBaseUrl()}/api/v1/static/afab?lang=${language}`,
      null,
      { credentials: false }
    );
  }

  async getChannelListing(language: LanguageTypeISO2) {
    return await this.httpClient.get(
      `${this.getBaseUrl()}/api/v1/listing/channel?lang=${language}`,
      null,
      { credentials: false }
    );
  }

  async getB40Token() {
    return await this.httpClient.post(
      `${this.getBaseUrl()}/api/v1/b40/auth`,
      {},
      { credentials: false }
    );
  }

  async verifyB40Status(identityCard: string, token: string, type: string) {
    return await this.httpClient.post(
      `${this.getBaseUrl()}/api/v1/b40/verify`,
      { identityCard: identityCard },
      { credentials: false, token: `${type} ${token}` }
    );
  }
}

export default DefaultNjoiApiProvider;
