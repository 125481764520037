import {
  ConfigRepository,
  ContentRepository
} from '@jsCore/src/types/repositories';
import { GetNjoiBoxContent } from '@jsCore/src/types/useCases';

export const getNjoiBoxUseCase = async (
  configRepository: ConfigRepository,
  contentRepository: ContentRepository
): Promise<GetNjoiBoxContent> => {
  const language = configRepository.getLanguage();
  return contentRepository.fetchGetNjoiContent(language);
};
