import {
  GetUpdateLeadErrorsUseCase,
  UpdateLeadErrorInputValidation
} from '@jsCore/src/types/useCases';

export const getUpdateLeadErrorsUseCase: GetUpdateLeadErrorsUseCase = (
  inputs
) => {
  const invalidData: UpdateLeadErrorInputValidation = {
    isPhoneNumberInValid: false,
    isAlternateNumberInValid: false,
    isEmailInValid: false,
    isErrorNotInCoverage: false
  };

  if (!inputs) return invalidData;

  inputs.forEach((element) => {
    const {
      context: { key }
    } = element.errorAttributes;

    if (key === 'phoneNumber') {
      invalidData.isPhoneNumberInValid = true;
    } else if (key === 'alternatePhoneNumber') {
      invalidData.isAlternateNumberInValid = true;
    } else if (key === 'email') {
      invalidData.isEmailInValid = true;
    } else {
      invalidData.isErrorNotInCoverage = true;
    }
  });

  return invalidData;
};
