import 'reflect-metadata';
import { Container, decorate, injectable, interfaces } from 'inversify';

// Client
import AxiosHttpClient from '@jsCore/src/clients/axios/AxiosHttpClient';
import LunrSearchClient from '@jsCore/src/clients/lunr/LunrSearchClient';

// Providers
import DefaultLocalStorageProvider from '@jsCore/src/providers/localStorage/LocalStorageProvider';
import DefaultNjoiApiProvider from '@jsCore/src/providers/njoiApi/NjoiApiProvider';
import DefaultSearchProvider from '@jsCore/src/providers/search/SearchProvider';
import DefaultNdsApiProvider from '@jsCore/src/providers/ndsApi/NdsApiProvider';
import DefaultAcmShopApiProvider from '@jsCore/src/providers/acmShopApi/AcmShopApiProvider';
import DefaultAcmCassandraApiProvider from '@jsCore/src/providers/acmCassandraApi/AcmCassandraApiProvider';
import DefaultUploadProvider from '@jsCore/src/providers/upload/uploadProvider';
import DefaultDigitalFortressApiProvider from '@jsCore/src/providers/digitalFortressApi/digitalFortressApiProvider';

// Repositories
import DefaultConfigRepository from '@jsCore/src/repositories/config/ConfigRepository';
import DefaultPurchaseRepository from '@jsCore/src/repositories/purchase/PurchaseRepository';
import DefaultHighlightRepository from '@jsCore/src/repositories/highlight/HighlightRepository';
import DefaultGreenFieldRepository from '@jsCore/src/repositories/greenField/GreenFieldRepository';
import DefaultContentRepository from '@jsCore/src/repositories/content/ContentRepository';
import DefaultUploadRepository from '@jsCore/src/repositories/upload/uploadRepository';
import DefaultSupportRepository from '@jsCore/src/repositories/support/SupportRepository';

// Use Cases
import { getLanguageUseCase } from '@jsCore/src/useCases/language/GetLanguageUseCase';
import { setLanguageUseCase } from '@jsCore/src/useCases/language/SetLanguageUseCase';
import { getAnnouncementUseCase } from '@jsCore/src/useCases/announcement/GetAnnouncementUseCase';
import { closeAnnouncementUseCase } from '@jsCore/src/useCases/announcement/CloseAnnouncementUseCase';
import { getCloseAnnouncementStatusUseCase } from '@jsCore/src/useCases/announcement/GetCloseAnnouncementStatusUseCase';
import { getPromotionUseCase } from '@jsCore/src/useCases/promotion/GetPromotionUseCase';
import { closePromotionUseCase } from '@jsCore/src/useCases/promotion/ClosePromotionUseCase';
import { getClosePromotionStatusUseCase } from '@jsCore/src/useCases/promotion/GetClosePromotionStatusUseCase';
import { searchSupportQuestionsUseCase } from '@jsCore/src/useCases/support/SearchSupportQuestionsUseCase';
import { getSupportQuestionsUseCase } from '@jsCore/src/useCases/support/GetSupportQuestionsUseCase';
import { getSupportCategoryDetailUseCase } from '@jsCore/src/useCases/support/GetSupportCategoryDetailUseCase';
import { getSupportQuestionDetailUseCase } from '@jsCore/src/useCases/support/GetSupportQuestionDetailUseCase';
import { submitLeadUseCase } from '@jsCore/src/useCases/contactus/SubmitLeadUseCase';
import { searchAddressUseCase } from '@jsCore/src/useCases/addressChecker/SearchAddressUseCase';
import { checkISPCoverageUseCase } from '@jsCore/src/useCases/addressChecker/checkISPCoverageUseCase';
import { generateLeadUseCase } from '@jsCore/src/useCases/leadVt/GenerateLeadUseCase';
import { updateLeadUseCase } from '@jsCore/src/useCases/leadVt/UpdateLeadUseCase';
import { getSignedUrlUseCase } from '@jsCore/src/useCases/leadVt/getSignedUrlUseCase';
import { deleteSignedDocUseCase } from '@jsCore/src/useCases/leadVt/deleteSignedDocUseCase';
import { uploadLeadAttachmentUseCase } from '@jsCore/src/useCases/uploadFile/uploadLeadAttachmentUseCase';
import { generateOTPUseCase } from '@jsCore/src/useCases/leadVt/generateOTPUseCase';
import { verifyOtpUseCase } from '@jsCore/src/useCases/leadVt/verifyOtpUseCase';
import { generateConsentUseCase } from '@jsCore/src/useCases/leadVt/GenerateConsentUseCase';
import { registerInterestUseCase } from '@jsCore/src/useCases/leadVt/RegisterInterestUseCase';
import { updateOrderAstroFibreDbUseCase } from '@jsCore/src/useCases/addressChecker/UpdateOrderAstroFibreDbUseCase';
import { validateEmailAddressUseCase } from '@jsCore/src/useCases/form/ValidateEmailAddressUseCase';
import { validatePostcodeUseCase } from '@jsCore/src/useCases/form/ValidatePostcodeUseCase';
import { validateNameUseCase } from '@jsCore/src/useCases/form/ValidateNameUseCase';
import { validateImageMetaUseCase } from '@jsCore/src/useCases/form/ValidateImageMetaUseCase';
import { getNjoiBoxUseCase } from '@jsCore/src/useCases/njoiBox/getNjoiBoxUseCase';
import { getNjoiComboDataUseCase } from '@jsCore/src/useCases/njoiCombo/getNjoiComboDataUseCase';
import { getNjoiCampaignUseCase } from './useCases/njoiCampaign/getNjoiCampaignUseCase';
import { getNjoiStoreArticleDetailUseCase } from '@jsCore/src/useCases/njoistorearticledetail/GetNjoiStoreArticleDetailUseCase';
import { getNjoiDistributorUseCase } from '@jsCore/src/useCases/njoiDistributor/njoiDistributorUseCase';
import { getUpdateLeadErrorsUseCase } from '@jsCore/src/useCases/leadVt/getUpdateLeadErrorsUseCase';
import { validateEntitledAstroFibreUseCase } from '@jsCore/src/useCases/getEntitledAstroFibre/validateEntitledAstroFibreUseCase';
import { retrieveErrorUseCase } from '@jsCore/src/useCases/getEntitledAstroFibre/retrieveErrorUseCase';
import { getConfigUseCase } from '@jsCore/src/useCases/config/getConfigUseCase';
import { getSearchResultUseCase } from '@jsCore/src/useCases/search/getSearchResultUseCase';
import { getNjoiListingUseCase } from '@jsCore/src/useCases/njoiListing/getNjoiListingUseCase';
import { getAddonPackListingUseCase } from '@jsCore/src/useCases/addonPackListing/getAddonPackListingUseCase';
import { getChannelListingUseCase } from '@jsCore/src/useCases/channelListing/getChannelListingUseCase';
import { getMovieListingUseCase } from '@jsCore/src/useCases/movieListing/getMovieListingUseCase';
import { getB40TokenUseCase } from '@jsCore/src/useCases/contactus/SubmitLeadUseCase';
import { getB40StatusUseCase } from '@jsCore/src/useCases/contactus/SubmitLeadUseCase';

// Services
import DefaultEnvironmentService from '@jsCore/src/services/environment/EnvironmentService';
import GtmAnalyticService from '@jsCore/src/services/analytics/GtmAnalyticService';
import DefaultAnalyticService from '@jsCore/src/services/analytics/DefaultAnalyticService';

// import DefaultSeoService from './web/services/seo/DefaultSeoService';
import { getChannelDetailUseCase } from '@jsCore/src/useCases/channelDetail/GetChannelDetailUseCase';
import { getMovieDetailUseCase } from '@jsCore/src/useCases/movieDetail/GetMovieDetailUseCase';
import { getBundleDetailUseCase } from '@jsCore/src/useCases/bundleDetail/GetBundleDetailUseCase';
import { getPackageDetailUseCase } from '@jsCore/src/useCases/packageDetail/GetPackageDetailUseCase';

import {
  AcmCassandraApiProvider,
  AcmShopApiProvider,
  ConsentInput,
  DeleteSignedDocInput,
  DigitalFortressApiProvider,
  GetSignedUrlInput,
  LocalStorageProvider,
  NdsApiProvider,
  NjoiApiProvider,
  RegisterInterestInput,
  SearchProvider,
  UploadLeadAttachmentInput,
  UploadProvider
} from '@jsCore/src/types/providers';

import {
  CheckISPCoverageUseCase,
  CloseAnnouncementUseCase,
  ClosePromotionUseCase,
  DeleteSignedDocUseCase,
  GenerateConsentUseCase,
  GenerateLeadUseCase,
  GenerateOTPUseCase,
  GetAddonPackListingUseCase,
  GetAnnouncementUseCase,
  GetB40StatusUseCase,
  GetB40TokenUseCase,
  GetBundleDetailUseCase,
  GetChannelDetailUseCase,
  GetChannelListingUseCase,
  GetCloseAnnouncementStatusUseCase,
  GetClosePromotionStatusUseCase,
  GetDigitalFortressConfigUseCase,
  GetLanguageUseCase,
  GetMovieDetailUseCase,
  GetMovieListingUseCase,
  GetNjoiBoxUseCase,
  GetNjoiComboUseCase,
  GetNjoiCampaignUseCase,
  GetNjoiDistributorUseCase,
  GetNjoiListingUseCase,
  GetNjoiStoreArticleDetailUseCase,
  GetPackageDetailUseCase,
  GetPromotionUseCase,
  GetSearchResultUseCase,
  GetSignedUrlUseCase,
  GetSupportCategoryDetailUseCase,
  GetSupportQuestionDetailUseCase,
  GetSupportQuestionsUseCase,
  GetUpdateLeadErrorsUseCase,
  RegisterInterestUseCase,
  RetrieveErrorResponse,
  RetrieveErrorUseCase,
  SearchAddressUseCase,
  SearchSupportQuestionsUseCase,
  SetLanguageUseCase,
  SubmitLeadUseCase,
  UpdateLeadErrorResponseInputs,
  UpdateLeadUseCase,
  UpdateOrderAstroFibreDbUseCase,
  UploadLeadAttachmentUseCase,
  ValidateEmailAddressUseCase,
  ValidateEntitledAstroFibreUseCase,
  ValidateImageMetaUseCase,
  ValidateNameUseCase,
  ValidatePostcodeUseCase,
  VerifyOTPUseCase
} from '@jsCore/src/types/useCases';
import {
  GreenFieldLeadInputs,
  GreenFieldSearchInputs,
  GreenFieldUpdateLeadInputs
} from '@jsCore/src/types/models/greenfield';
import {
  GenerateOTPInputs,
  VerifyOTPInputs
} from '@jsCore/src/types/models/otp';
import { ContactUsInputs } from '@jsCore/src/types/models/contactus';
import {
  AnalyticService,
  EnvironmentService
} from '@jsCore/src/types/services';
import { HttpClient, SearchClient } from '@jsCore/src/types/clients';
import {
  ConfigRepository,
  ContentRepository,
  GreenFieldRepository,
  HighlightRepository,
  PurchaseRepository,
  SupportRepository,
  UploadRepository
} from '@jsCore/src/types/repositories';
import { LanguageTypeISO2 } from '@jsCore/src/types/language';

const CLIENT_TYPES = {
  Http: Symbol.for('HttpClient'),
  Search: Symbol.for('SearchClient')
};

const PROVIDER_TYPES = {
  LocalStorage: Symbol.for('LocalStorageProvider'),
  SessionStorage: Symbol.for('SessionStorageProvider'),
  NjoiApi: Symbol.for('NjoiApiProvider'),
  Search: Symbol.for('SearchProvider'),
  NdsApi: Symbol.for('NdsApiProvider'),
  AcmCassandraApi: Symbol.for('AcmCassandraApiProvider'),
  AcmShopApi: Symbol.for('AcmShopApiProvider'),
  Upload: Symbol.for('UploadProvider'),
  DigitalFortressApi: Symbol.for('DigitalFortressApiProvider')
};

const REPOSITORY_TYPES = {
  Config: Symbol.for('ConfigRepository'),
  Support: Symbol.for('SupportRepository'),
  Purchase: Symbol.for('PurchaseRepository'),
  Highlight: Symbol.for('HighlightRepository'),
  GreenField: Symbol.for('GreenFieldRepository'),
  Content: Symbol.for('ContentRepository'),
  Upload: Symbol.for('UploadRepository')
};

const USECASE_TYPES = {
  GetLanguage: Symbol.for('GetLanguage'),
  SetLanguage: Symbol.for('SetLanguage'),
  GetAnnouncement: Symbol.for('GetAnnouncement'),
  GetCloseAnnouncementStatus: Symbol.for('GetCloseAnnouncementStatus'),
  CloseAnnouncement: Symbol.for('CloseAnnouncement'),
  GetPromotion: Symbol.for('GetPromotion'),
  GetClosePromotionStatus: Symbol.for('GetClosePromotionStatus'),
  ClosePromotion: Symbol.for('ClosePromotion'),
  GetSupportQuestions: Symbol.for('GetSupportQuestions'),
  SearchSupportQuestions: Symbol.for('SearchSupportQuestions'),
  GetSupportCategoryDetail: Symbol.for('GetSupportCategoryDetail'),
  GetSupportQuestionDetail: Symbol.for('GetSupportQuestionDetail'),
  SubmitLead: Symbol.for('SubmitLead'),
  SearchAddress: Symbol.for('SearchAddress'),
  CheckISPCoverage: Symbol.for('CheckISPCoverage'),
  GenerateLead: Symbol.for('GenerateLead'),
  UpdateLead: Symbol.for('UpdateLead'),
  GetSignedUrl: Symbol.for('GetSignedUrl'),
  DeleteSignedDocument: Symbol.for('DeleteSignedDocument'),
  GenerateOTP: Symbol.for('GenerateOTP'),
  VerifyOTP: Symbol.for('VerifyOTP'),
  GenerateConsent: Symbol.for('GenerateConsent'),
  RegisterInterest: Symbol.for('RegisterInterest'),
  ValidateEntitledAstroFibre: Symbol.for('GetEntitledAstroUser'),
  ValidateEmailAddress: Symbol.for('ValidateEmailAddress'),
  ValidatePostcode: Symbol.for('ValidatePostcode'),
  ValidateName: Symbol.for('ValidateName'),
  ValidateImageMeta: Symbol.for('ValidateImageMeta'),
  GetNjoiBox: Symbol.for('GetNjoiBox'),
  GetNjoiComboData: Symbol.for('GetNjoiComboData'),
  GetNjoiCampaign: Symbol.for('GetNjoiCampaign'),
  GetNjoiStoreArticleDetail: Symbol.for('GetNjoiStoreArticleDetailUseCase'),
  GetNjoiDistributor: Symbol.for('NjoiDistributor'),
  RetrieveError: Symbol.for('RetrieveError'),
  UploadLeadAttachment: Symbol.for('UploadLeadAttachment'),
  UpdateOrderAstroFibreDb: Symbol.for('UpdateOrderAstroFibreDb'),
  GetUpdateLeadErrors: Symbol.for('GetUpdateLeadErrors'),
  GetConfig: Symbol.for('GetConfig'),
  GetMovieDetail: Symbol.for('GetMovieDetail'),
  GetChannelDetail: Symbol.for('GetChannelDetail'),
  GetPackageDetail: Symbol.for('GetPackageDetail'),
  GetBundleDetail: Symbol.for('GetBundleDetail'),
  GetSearchResult: Symbol.for('GetSearchResult'),
  GetNjoiListing: Symbol.for('GetNjoiListing'),
  GetAddonPackListing: Symbol.for('GetAddonPackListing'),
  GetMovieListing: Symbol.for('GetMovieListing'),
  GetChannelListing: Symbol.for('GetChannelListing'),
  GetB40Token: Symbol.for('GetB40Token'),
  GetB40Status: Symbol.for('GetB40Status')
};

const SERVICE_TYPES = {
  Environment: Symbol.for('EnvironmentService'),
  Analytic: Symbol.for('AnalyticService'),
  Seo: Symbol.for('SeoService')
};

const MODEL_TYPES = {
  Environment: Symbol.for('Environment')
};

export {
  CLIENT_TYPES,
  PROVIDER_TYPES,
  REPOSITORY_TYPES,
  USECASE_TYPES,
  SERVICE_TYPES,
  MODEL_TYPES
};

const LAZY_INVERSIFY_CLIENT = {
  // LunrSearchClient: {
  //   symbol: CLIENT_TYPES.Search,
  //   lazyImport: () =>
  //     import(
  //       /* webpackChunkName: "LunrSearchClient" */ '@jsCore/src/clients/lunr/LunrSearchClient'
  //     )
  // }
};

const LAZY_INVERSIFY_REPO = {
  // SupportRepository: {
  //   symbol: REPOSITORY_TYPES.Support,
  //   params: [PROVIDER_TYPES.NjoiApi, PROVIDER_TYPES.Search],
  //   lazyImport: () =>
  //     import(
  //       /* webpackChunkName: "SupportRepository" */ '@jsCore/src/repositories/support/SupportRepository'
  //     )
  // }
};

export class Dependency {
  // Variables
  private static defaultContainer: Container;
  private static existingDependency: any = {};

  /**
   * Get dependency based on it's `symbol`.
   * @param {string | symbol} symbol Dependency reference.
   * @returns {type} Requested dependency.
   */
  public static get<T>(symbol: string | symbol): T {
    return Dependency.defaultContainer.get<T>(symbol);
  }

  public static setup(
    registerExternalDependancies?: (container: Container) => void,
    registerMockDependancies?: (container: Container) => void
  ): Container {
    const container: Container = new Container();

    if (typeof registerExternalDependancies === 'function') {
      registerExternalDependancies(container);
    }

    Dependency.registerClients(container);
    Dependency.registerProviders(container);
    Dependency.registerRepositories(container);
    Dependency.registerUseCases(container);
    Dependency.registerServices(container);

    if (typeof registerMockDependancies === 'function') {
      registerMockDependancies(container);
    }

    Dependency.defaultContainer = container;

    return Dependency.defaultContainer;
  }

  public static async injectDependency(
    input: keyof typeof LAZY_INVERSIFY_CLIENT | keyof typeof LAZY_INVERSIFY_REPO
  ) {
    if (!Dependency.existingDependency[input]) {
      const lazyLoadArgs: any[] = [Dependency.defaultContainer];
      let container: any;
      if (LAZY_INVERSIFY_REPO.hasOwnProperty(input)) {
        container =
          LAZY_INVERSIFY_REPO[input as keyof typeof LAZY_INVERSIFY_REPO];
      } else {
        container =
          LAZY_INVERSIFY_CLIENT[input as keyof typeof LAZY_INVERSIFY_CLIENT];
      }
      lazyLoadArgs.push(container.symbol);
      const LazyLoadedDep = (await container.lazyImport()).default;
      lazyLoadArgs.push((context: any) => {
        let args: any = [];
        if (container.params) {
          container.params.forEach((param: symbol) =>
            args.push(context.container.get(param))
          );
        }
        return new LazyLoadedDep(...args);
      });
      // @ts-ignore */}
      Dependency.decorateAnnotate(...lazyLoadArgs);
      Dependency.existingDependency[input] = true;
    }
  }

  private static registerClients(container: Container) {
    Dependency.decorateAnnotate<HttpClient>(
      container,
      CLIENT_TYPES.Http,
      () => new AxiosHttpClient()
    );

    Dependency.decorateAnnotate<SearchClient>(
      container,
      CLIENT_TYPES.Search,
      () => new LunrSearchClient()
    );
  }

  private static registerProviders(container: Container) {
    /**
     * note: session storage and local storage have no different,
     * except data in session storage will expire once user close the tab/browser.
     * BUT, both of it use `Storage` type
     */
    Dependency.decorateAnnotate<LocalStorageProvider>(
      container,
      PROVIDER_TYPES.LocalStorage,
      () => new DefaultLocalStorageProvider(localStorage)
    );

    Dependency.decorateAnnotate<LocalStorageProvider>(
      container,
      PROVIDER_TYPES.SessionStorage,
      () => new DefaultLocalStorageProvider(sessionStorage)
    );

    Dependency.decorateAnnotate<NjoiApiProvider>(
      container,
      PROVIDER_TYPES.NjoiApi,
      (context) =>
        new DefaultNjoiApiProvider(
          context.container.get(CLIENT_TYPES.Http),
          context.container.get(SERVICE_TYPES.Environment)
        )
    );

    Dependency.decorateAnnotate<DigitalFortressApiProvider>(
      container,
      PROVIDER_TYPES.DigitalFortressApi,
      (context) =>
        new DefaultDigitalFortressApiProvider(
          context.container.get(CLIENT_TYPES.Http),
          context.container.get(SERVICE_TYPES.Environment)
        )
    );

    Dependency.decorateAnnotate<NdsApiProvider>(
      container,
      PROVIDER_TYPES.NdsApi,
      (context) =>
        new DefaultNdsApiProvider(
          context.container.get(CLIENT_TYPES.Http),
          context.container.get(SERVICE_TYPES.Environment)
        )
    );

    Dependency.decorateAnnotate<AcmCassandraApiProvider>(
      container,
      PROVIDER_TYPES.AcmCassandraApi,
      (context) =>
        new DefaultAcmCassandraApiProvider(
          context.container.get(CLIENT_TYPES.Http),
          context.container.get(SERVICE_TYPES.Environment)
        )
    );

    Dependency.decorateAnnotate<AcmShopApiProvider>(
      container,
      PROVIDER_TYPES.AcmShopApi,
      (context) =>
        new DefaultAcmShopApiProvider(
          context.container.get(CLIENT_TYPES.Http),
          context.container.get(SERVICE_TYPES.Environment)
        )
    );

    Dependency.decorateAnnotate<SearchProvider>(
      container,
      PROVIDER_TYPES.Search,
      (context) =>
        new DefaultSearchProvider(context.container.get(CLIENT_TYPES.Search))
    );

    Dependency.decorateAnnotate<UploadProvider>(
      container,
      PROVIDER_TYPES.Upload,
      (context) =>
        new DefaultUploadProvider(context.container.get(CLIENT_TYPES.Http))
    );
  }

  private static registerRepositories(container: Container) {
    Dependency.decorateAnnotate<ConfigRepository>(
      container,
      REPOSITORY_TYPES.Config,
      (context) =>
        new DefaultConfigRepository(
          context.container.get(PROVIDER_TYPES.LocalStorage),
          context.container.get(PROVIDER_TYPES.DigitalFortressApi)
        )
    );

    Dependency.decorateAnnotate<SupportRepository>(
      container,
      REPOSITORY_TYPES.Support,
      (context) =>
        new DefaultSupportRepository(
          context.container.get(PROVIDER_TYPES.NjoiApi),
          context.container.get(CLIENT_TYPES.Search)
        )
    );

    Dependency.decorateAnnotate<PurchaseRepository>(
      container,
      REPOSITORY_TYPES.Purchase,
      (context) =>
        new DefaultPurchaseRepository(
          context.container.get(PROVIDER_TYPES.NdsApi)
        )
    );

    Dependency.decorateAnnotate<GreenFieldRepository>(
      container,
      REPOSITORY_TYPES.GreenField,
      (context) =>
        new DefaultGreenFieldRepository(
          context.container.get(PROVIDER_TYPES.AcmShopApi),
          context.container.get(PROVIDER_TYPES.AcmCassandraApi),
          context.container.get(PROVIDER_TYPES.SessionStorage),
          context.container.get(SERVICE_TYPES.Environment),
          context.container.get(PROVIDER_TYPES.NjoiApi)
        )
    );

    Dependency.decorateAnnotate<HighlightRepository>(
      container,
      REPOSITORY_TYPES.Highlight,
      (context) =>
        new DefaultHighlightRepository(
          context.container.get(PROVIDER_TYPES.NjoiApi),
          context.container.get(PROVIDER_TYPES.SessionStorage)
        )
    );

    Dependency.decorateAnnotate<ContentRepository>(
      container,
      REPOSITORY_TYPES.Content,
      (context) =>
        new DefaultContentRepository(
          context.container.get(PROVIDER_TYPES.NjoiApi)
        )
    );

    Dependency.decorateAnnotate<UploadRepository>(
      container,
      REPOSITORY_TYPES.Upload,
      (context) =>
        new DefaultUploadRepository(
          context.container.get(PROVIDER_TYPES.Upload)
        )
    );
  }

  private static registerUseCases(container: Container) {
    // language use case
    Dependency.decorateAnnotate<GetLanguageUseCase>(
      container,
      USECASE_TYPES.GetLanguage,
      (context) => {
        return () =>
          getLanguageUseCase(context.container.get(REPOSITORY_TYPES.Config));
      }
    );

    Dependency.decorateAnnotate<SetLanguageUseCase>(
      container,
      USECASE_TYPES.SetLanguage,
      (context) => {
        return (language: LanguageTypeISO2) =>
          setLanguageUseCase(
            context.container.get(REPOSITORY_TYPES.Config),
            language
          );
      }
    );

    // Announcement use cases
    Dependency.decorateAnnotate<GetAnnouncementUseCase>(
      container,
      USECASE_TYPES.GetAnnouncement,
      (context) => {
        return () =>
          getAnnouncementUseCase(
            context.container.get(REPOSITORY_TYPES.Highlight),
            context.container.get(REPOSITORY_TYPES.Config)
          );
      }
    );

    Dependency.decorateAnnotate<CloseAnnouncementUseCase>(
      container,
      USECASE_TYPES.CloseAnnouncement,
      (context) => {
        return () =>
          closeAnnouncementUseCase(
            context.container.get(REPOSITORY_TYPES.Highlight)
          );
      }
    );

    Dependency.decorateAnnotate<GetCloseAnnouncementStatusUseCase>(
      container,
      USECASE_TYPES.GetCloseAnnouncementStatus,
      (context) => {
        return () =>
          getCloseAnnouncementStatusUseCase(
            context.container.get(REPOSITORY_TYPES.Highlight)
          );
      }
    );

    // Promotion use cases
    Dependency.decorateAnnotate<GetPromotionUseCase>(
      container,
      USECASE_TYPES.GetPromotion,
      (context) => {
        return () =>
          getPromotionUseCase(
            context.container.get(REPOSITORY_TYPES.Highlight),
            context.container.get(REPOSITORY_TYPES.Config)
          );
      }
    );

    Dependency.decorateAnnotate<ClosePromotionUseCase>(
      container,
      USECASE_TYPES.ClosePromotion,
      (context) => {
        return () =>
          closePromotionUseCase(
            context.container.get(REPOSITORY_TYPES.Highlight)
          );
      }
    );

    Dependency.decorateAnnotate<GetClosePromotionStatusUseCase>(
      container,
      USECASE_TYPES.GetClosePromotionStatus,
      (context) => {
        return () =>
          getClosePromotionStatusUseCase(
            context.container.get(REPOSITORY_TYPES.Highlight)
          );
      }
    );

    this.registerSupportUseCases(container);

    Dependency.decorateAnnotate<ValidateEmailAddressUseCase>(
      container,
      USECASE_TYPES.ValidateEmailAddress,
      () => (email?: string | null) => validateEmailAddressUseCase(email)
    );

    Dependency.decorateAnnotate<ValidatePostcodeUseCase>(
      container,
      USECASE_TYPES.ValidatePostcode,
      () => (postcode?: string | null) => validatePostcodeUseCase(postcode)
    );

    Dependency.decorateAnnotate<ValidateNameUseCase>(
      container,
      USECASE_TYPES.ValidateName,
      () => (name?: string | null) => validateNameUseCase(name)
    );

    this.registerNjoiStoreUseCases(container);
    this.registerAstroFibreUseCases(container);
  }

  private static registerSupportUseCases(container: Container) {
    Dependency.decorateAnnotate<GetSupportQuestionsUseCase>(
      container,
      USECASE_TYPES.GetSupportQuestions,
      (context) => {
        return () =>
          getSupportQuestionsUseCase(
            context.container.get(REPOSITORY_TYPES.Support),
            context.container.get(REPOSITORY_TYPES.Config)
          );
      }
    );

    Dependency.decorateAnnotate<SearchSupportQuestionsUseCase>(
      container,
      USECASE_TYPES.SearchSupportQuestions,
      (context) => {
        return (searchText: string) =>
          searchSupportQuestionsUseCase(
            context.container.get(REPOSITORY_TYPES.Support),
            searchText
          );
      }
    );

    Dependency.decorateAnnotate<GetSupportCategoryDetailUseCase>(
      container,
      USECASE_TYPES.GetSupportCategoryDetail,
      (context) => {
        return (slug: string) =>
          getSupportCategoryDetailUseCase(
            context.container.get(REPOSITORY_TYPES.Support),
            slug
          );
      }
    );

    Dependency.decorateAnnotate<GetSupportQuestionDetailUseCase>(
      container,
      USECASE_TYPES.GetSupportQuestionDetail,
      (context) => {
        return (slug: string) =>
          getSupportQuestionDetailUseCase(
            context.container.get(REPOSITORY_TYPES.Support),
            slug
          );
      }
    );
  }

  private static registerNjoiStoreUseCases(container: Container) {
    Dependency.decorateAnnotate<GetNjoiBoxUseCase>(
      container,
      USECASE_TYPES.GetNjoiBox,
      (context) => {
        return () =>
          getNjoiBoxUseCase(
            context.container.get(REPOSITORY_TYPES.Config),
            context.container.get(REPOSITORY_TYPES.Content)
          );
      }
    );

    Dependency.decorateAnnotate<GetNjoiComboUseCase>(
      container,
      USECASE_TYPES.GetNjoiComboData,
      (context) => {
        return () =>
          getNjoiComboDataUseCase(
            context.container.get(REPOSITORY_TYPES.Config),
            context.container.get(REPOSITORY_TYPES.Content)
          );
      }
    );

    Dependency.decorateAnnotate<GetNjoiCampaignUseCase>(
      container,
      USECASE_TYPES.GetNjoiCampaign,
      (context) => {
        return () =>
          getNjoiCampaignUseCase(
            context.container.get(REPOSITORY_TYPES.Config),
            context.container.get(REPOSITORY_TYPES.Content)
          );
      }
    );

    Dependency.decorateAnnotate<GetNjoiStoreArticleDetailUseCase>(
      container,
      USECASE_TYPES.GetNjoiStoreArticleDetail,

      (context) => {
        return (slug: string) =>
          getNjoiStoreArticleDetailUseCase(
            context.container.get(REPOSITORY_TYPES.Config),
            slug,
            context.container.get(REPOSITORY_TYPES.Content)
          );
      }
    );

    Dependency.decorateAnnotate<GetNjoiDistributorUseCase>(
      container,
      USECASE_TYPES.GetNjoiDistributor,
      (context) => (state: string) =>
        getNjoiDistributorUseCase(
          state,
          context.container.get(REPOSITORY_TYPES.Content)
        )
    );

    Dependency.decorateAnnotate<SubmitLeadUseCase>(
      container,
      USECASE_TYPES.SubmitLead,
      (context) => (inputs: ContactUsInputs) =>
        submitLeadUseCase(
          context.container.get(REPOSITORY_TYPES.Purchase),
          inputs
        )
    );

    Dependency.decorateAnnotate<GetB40TokenUseCase>(
      container,
      USECASE_TYPES.GetB40Token,
      (context) => () =>
        getB40TokenUseCase(context.container.get(REPOSITORY_TYPES.Content))
    );

    Dependency.decorateAnnotate<GetB40StatusUseCase>(
      container,
      USECASE_TYPES.GetB40Status,
      (context) =>
        (inputs: { identityCard: string; token: string; type: string }) =>
          getB40StatusUseCase(
            context.container.get(REPOSITORY_TYPES.Content),
            inputs
          )
    );

    Dependency.decorateAnnotate<GetChannelDetailUseCase>(
      container,
      USECASE_TYPES.GetChannelDetail,
      (context) => (slug: string) =>
        getChannelDetailUseCase(
          context.container.get(REPOSITORY_TYPES.Config),
          slug,
          context.container.get(REPOSITORY_TYPES.Content)
        )
    );

    Dependency.decorateAnnotate<GetMovieDetailUseCase>(
      container,
      USECASE_TYPES.GetMovieDetail,
      (context) => (slug: string) =>
        getMovieDetailUseCase(
          context.container.get(REPOSITORY_TYPES.Config),
          slug,
          context.container.get(REPOSITORY_TYPES.Content)
        )
    );

    Dependency.decorateAnnotate<GetBundleDetailUseCase>(
      container,
      USECASE_TYPES.GetBundleDetail,
      (context) => (slug: string) =>
        getBundleDetailUseCase(
          context.container.get(REPOSITORY_TYPES.Config),
          slug,
          context.container.get(REPOSITORY_TYPES.Content)
        )
    );

    Dependency.decorateAnnotate<GetPackageDetailUseCase>(
      container,
      USECASE_TYPES.GetPackageDetail,
      (context) => (slug: string) =>
        getPackageDetailUseCase(
          context.container.get(REPOSITORY_TYPES.Config),
          slug,
          context.container.get(REPOSITORY_TYPES.Content)
        )
    );

    Dependency.decorateAnnotate<GetSearchResultUseCase>(
      container,
      USECASE_TYPES.GetSearchResult,
      (context) => (keyword: string) =>
        getSearchResultUseCase(
          context.container.get(REPOSITORY_TYPES.Config),
          keyword,
          context.container.get(REPOSITORY_TYPES.Content)
        )
    );

    Dependency.decorateAnnotate<GetNjoiListingUseCase>(
      container,
      USECASE_TYPES.GetNjoiListing,
      (context) => (link: string) =>
        getNjoiListingUseCase(
          context.container.get(REPOSITORY_TYPES.Config),
          context.container.get(REPOSITORY_TYPES.Content),
          link
        )
    );

    Dependency.decorateAnnotate<GetAddonPackListingUseCase>(
      container,
      USECASE_TYPES.GetAddonPackListing,
      (context) => () =>
        getAddonPackListingUseCase(
          context.container.get(REPOSITORY_TYPES.Config),
          context.container.get(REPOSITORY_TYPES.Content)
        )
    );

    Dependency.decorateAnnotate<GetChannelListingUseCase>(
      container,
      USECASE_TYPES.GetChannelListing,
      (context) => () =>
        getChannelListingUseCase(
          context.container.get(REPOSITORY_TYPES.Config),
          context.container.get(REPOSITORY_TYPES.Content)
        )
    );

    Dependency.decorateAnnotate<GetMovieListingUseCase>(
      container,
      USECASE_TYPES.GetMovieListing,
      (context) => () =>
        getMovieListingUseCase(
          context.container.get(REPOSITORY_TYPES.Config),
          context.container.get(REPOSITORY_TYPES.Content)
        )
    );
  }

  private static registerAstroFibreUseCases(container: Container) {
    Dependency.decorateAnnotate<ValidateImageMetaUseCase>(
      container,
      USECASE_TYPES.ValidateImageMeta,
      () => (type: string, size: number) => validateImageMetaUseCase(type, size)
    );

    Dependency.decorateAnnotate<SearchAddressUseCase>(
      container,
      USECASE_TYPES.SearchAddress,
      (context) => (inputs: GreenFieldSearchInputs) =>
        searchAddressUseCase(
          context.container.get(REPOSITORY_TYPES.GreenField),
          inputs
        )
    );

    Dependency.decorateAnnotate<CheckISPCoverageUseCase>(
      container,
      USECASE_TYPES.CheckISPCoverage,
      (context) => (inputs: GreenFieldSearchInputs) =>
        checkISPCoverageUseCase(
          context.container.get(REPOSITORY_TYPES.GreenField),
          inputs
        )
    );

    Dependency.decorateAnnotate<GenerateLeadUseCase>(
      container,
      USECASE_TYPES.GenerateLead,
      (context) => (inputs: GreenFieldLeadInputs) =>
        generateLeadUseCase(
          context.container.get(REPOSITORY_TYPES.GreenField),
          inputs
        )
    );

    Dependency.decorateAnnotate<UpdateLeadUseCase>(
      container,
      USECASE_TYPES.UpdateLead,
      (context) => (inputs: GreenFieldUpdateLeadInputs) =>
        updateLeadUseCase(
          context.container.get(REPOSITORY_TYPES.GreenField),
          inputs
        )
    );

    Dependency.decorateAnnotate<GetUpdateLeadErrorsUseCase>(
      container,
      USECASE_TYPES.GetUpdateLeadErrors,
      () => (inputs: UpdateLeadErrorResponseInputs) =>
        getUpdateLeadErrorsUseCase(inputs)
    );

    Dependency.decorateAnnotate<GetSignedUrlUseCase>(
      container,
      USECASE_TYPES.GetSignedUrl,
      (context) => (inputs: GetSignedUrlInput) =>
        getSignedUrlUseCase(
          context.container.get(REPOSITORY_TYPES.GreenField),
          inputs
        )
    );

    Dependency.decorateAnnotate<DeleteSignedDocUseCase>(
      container,
      USECASE_TYPES.DeleteSignedDocument,
      (context) => (inputs: DeleteSignedDocInput) =>
        deleteSignedDocUseCase(
          context.container.get(REPOSITORY_TYPES.GreenField),
          inputs.documentId,
          inputs.extension
        )
    );

    Dependency.decorateAnnotate<GenerateOTPUseCase>(
      container,
      USECASE_TYPES.GenerateOTP,
      (context) => (inputs: GenerateOTPInputs) =>
        generateOTPUseCase(
          context.container.get(REPOSITORY_TYPES.GreenField),
          inputs
        )
    );

    Dependency.decorateAnnotate<VerifyOTPUseCase>(
      container,
      USECASE_TYPES.VerifyOTP,
      (context) => (inputs: VerifyOTPInputs) =>
        verifyOtpUseCase(
          context.container.get(REPOSITORY_TYPES.GreenField),
          inputs
        )
    );

    Dependency.decorateAnnotate<GenerateConsentUseCase>(
      container,
      USECASE_TYPES.GenerateConsent,
      (context) => (inputs: ConsentInput) =>
        generateConsentUseCase(
          context.container.get(REPOSITORY_TYPES.GreenField),
          inputs
        )
    );

    Dependency.decorateAnnotate<UpdateOrderAstroFibreDbUseCase>(
      container,
      USECASE_TYPES.UpdateOrderAstroFibreDb,
      (context) => (token: string) =>
        updateOrderAstroFibreDbUseCase(
          context.container.get(REPOSITORY_TYPES.GreenField),
          token
        )
    );

    Dependency.decorateAnnotate<RegisterInterestUseCase>(
      container,
      USECASE_TYPES.RegisterInterest,
      (context) => (inputs: RegisterInterestInput) =>
        registerInterestUseCase(
          context.container.get(REPOSITORY_TYPES.GreenField),
          inputs
        )
    );

    Dependency.decorateAnnotate<ValidateEntitledAstroFibreUseCase>(
      container,
      USECASE_TYPES.ValidateEntitledAstroFibre,
      (context) => (token: string) =>
        validateEntitledAstroFibreUseCase(
          context.container.get(REPOSITORY_TYPES.GreenField),
          token
        )
    );

    Dependency.decorateAnnotate<RetrieveErrorUseCase>(
      container,
      USECASE_TYPES.RetrieveError,
      () => (response: RetrieveErrorResponse) => retrieveErrorUseCase(response)
    );

    Dependency.decorateAnnotate<UploadLeadAttachmentUseCase>(
      container,
      USECASE_TYPES.UploadLeadAttachment,
      (context) => (inputs: UploadLeadAttachmentInput) =>
        uploadLeadAttachmentUseCase(
          context.container.get(REPOSITORY_TYPES.Upload),
          context.container.get(REPOSITORY_TYPES.GreenField),
          inputs
        )
    );

    Dependency.decorateAnnotate<GetDigitalFortressConfigUseCase>(
      container,
      USECASE_TYPES.GetConfig,
      (context) => {
        return () =>
          getConfigUseCase(context.container.get(REPOSITORY_TYPES.Config));
      }
    );
  }

  private static registerServices(container: Container) {
    Dependency.decorateAnnotate<EnvironmentService>(
      container,
      SERVICE_TYPES.Environment,
      (context) =>
        new DefaultEnvironmentService(
          context.container.get(MODEL_TYPES.Environment)
        )
    );

    Dependency.decorateAnnotate<AnalyticService>(
      container,
      SERVICE_TYPES.Analytic,
      () => {
        let services: AnalyticService[] = [new GtmAnalyticService()];
        return new DefaultAnalyticService(services);
      }
    );

    // Dependency.decorateAnnotate<SeoService>(
    //   container,
    //   SERVICE_TYPES.Seo,
    //   () => new DefaultSeoService()
    // );
  }

  /**
   * @param {Container} container inversify container
   * @param {symbol} injectSymbol symbol of the dependency
   * @param {any} target dependency to be decorated
   * @param isSingleton
   */
  private static decorateAnnotate<T>(
    container: Container,
    injectSymbol: symbol,
    target: (context: interfaces.Context) => T,
    isSingleton: boolean = true
  ) {
    const binding = container.bind<T>(injectSymbol).toDynamicValue(target);
    if (isSingleton) binding.inSingletonScope();

    decorate(injectable(), target);
  }
}
