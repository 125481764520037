import {
  ConfigRepository,
  ContentRepository
} from '@jsCore/src/types/repositories';
import { AddOnPack, ChannelListingItem } from '@jsCore/src/types/providers';

export const getChannelListingUseCase = async (
  configRepository: ConfigRepository,
  contentRepository: ContentRepository
): Promise<ChannelListingItem[]> => {
  const language = configRepository.getLanguage();
  return contentRepository.fetchChannelListing(language);
};
