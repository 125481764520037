import {
  ConfigRepository,
  ContentRepository
} from '@jsCore/src/types/repositories';
import { AddOnPack } from '@jsCore/src/types/providers';

export const getAddonPackListingUseCase = async (
  configRepository: ConfigRepository,
  contentRepository: ContentRepository
): Promise<AddOnPack[]> => {
  const language = configRepository.getLanguage();
  return contentRepository.fetchAddonPackListing(language);
};
