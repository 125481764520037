import {
  LocalStorageProvider,
  LocalStorageValueType
} from '@jsCore/src/types/providers';

class DefaultLocalStorageProvider implements LocalStorageProvider {
  private storage: Storage;

  constructor(storage: Storage) {
    this.storage = storage;
  }

  private isJSON(item: string) {
    try {
      JSON.parse(item);
    } catch (e) {
      return false;
    }

    return true;
  }

  private isNumeric(item: string) {
    return /^-?\d+$/.test(item);
  }

  length() {
    return this.storage.length;
  }

  key(index: number) {
    return this.storage.key(index);
  }

  getItem(name: string) {
    const item = this.storage.getItem(name);

    if (item) {
      if (item === 'true' || item === 'false') {
        return item === 'true';
      } else if (this.isNumeric(item)) {
        return parseInt(item);
      } else if (this.isJSON(item)) {
        return JSON.parse(item);
      }
    }

    return item;
  }

  setItem(name: string, value: LocalStorageValueType) {
    let item: LocalStorageValueType;

    switch (typeof value) {
      case 'object':
        item = JSON.stringify(value);
        break;
      case 'number':
        item = value.toString();
        break;
      case 'boolean':
        item = value.toString();
        break;
      default:
        item = value;
    }

    return this.storage.setItem(name, item);
  }

  removeItem(name: string) {
    return this.storage.removeItem(name);
  }

  clear() {
    return this.storage.clear();
  }
}

export default DefaultLocalStorageProvider;
