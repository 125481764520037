import { AnalyticService, DataLayerType } from '@jsCore/src/types/services';

class DefaultAnalyticService implements AnalyticService {
  private services: AnalyticService[];

  constructor(services: AnalyticService[]) {
    this.services = services;
  }

  sendEvent(dataLayer: DataLayerType) {
    this.services.forEach(function (service) {
      service.sendEvent(dataLayer);
    });
  }
}

export default DefaultAnalyticService;
