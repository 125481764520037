import {
  ConfigRepository,
  ContentRepository
} from '@jsCore/src/types/repositories';
import { BundleDetail } from '@jsCore/src/types/providers';

export const getBundleDetailUseCase = (
  configRepository: ConfigRepository,
  slug: string,
  contentRepository: ContentRepository
): Promise<BundleDetail> => {
  const language = configRepository.getLanguage();
  return contentRepository.fetchBundleDetail(language, slug);
};
