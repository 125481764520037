import { NjoiPromotion } from '@jsCore/src/types/providers';
import {
  HighlightRepository,
  ConfigRepository
} from '@jsCore/src/types/repositories';

export const getPromotionUseCase = async (
  highlightRepository: HighlightRepository,
  configRepository: ConfigRepository
): Promise<NjoiPromotion> => {
  const language = configRepository.getLanguage();
  return await highlightRepository.fetchPromotion(language);
};
