import { Environment } from '@jsCore/src/types/models/environment';

abstract class DefaultEnvironment implements Environment {
  getNjoiApiBaseUrl(): string {
    return process.env.REACT_APP_NJOI_API_URL!;
  }

  getNjoiWebFlowUrl(): string {
    return process.env.REACT_APP_NJOI_WEBFLOW_URL!;
  }

  getDigitalFortressBaseUrl(): string {
    return process.env.REACT_APP_DF_API_URL!;
  }

  getGtmContainerId(): string {
    return process.env.REACT_APP_GTM_CONTAINER_ID!;
  }

  getNdsApiBaseURL(): string {
    return process.env.REACT_APP_NDS_API_URL!;
  }

  getNdsApiKey(): string {
    return process.env.REACT_APP_CONTACT_US_API_KEY!;
  }

  getContactUsAPIPath(): string {
    return process.env.REACT_APP_CONTACT_API_PATH!;
  }

  isContactEnabled(): string {
    return process.env.REACT_APP_CONTACT_US_CONTACT!;
  }

  getAcmShopApiBaseUrl(): string {
    return process.env.REACT_APP_ACM_SHOP_API_URL!;
  }

  getAcmCassandraApiBaseUrl(): string {
    return process.env.REACT_APP_ACM_CASSANDRA_API_URL!;
  }

  getBuildVersion(): string {
    return process.env.REACT_APP_BUILD_VERSION!;
  }

  getBuildNumber(): string {
    return '1';
  }

  abstract isProduction(): boolean;
}

export default DefaultEnvironment;
