import {
  ConfigRepository,
  ContentRepository
} from '@jsCore/src/types/repositories';
import { GetNjoiCombo } from '@jsCore/src/types/useCases';

export const getNjoiComboDataUseCase = async (
  configRepository: ConfigRepository,
  contentRepository: ContentRepository
): Promise<GetNjoiCombo> => {
  const language = configRepository.getLanguage();
  return contentRepository.fetchNjoiCombo(language);
};
