import {
  ConfigRepository,
  ContentRepository
} from '@jsCore/src/types/repositories';
import { MovieListingItem } from '@jsCore/src/types/providers';

export const getMovieListingUseCase = async (
  configRepository: ConfigRepository,
  contentRepository: ContentRepository
): Promise<MovieListingItem[]> => {
  const language = configRepository.getLanguage();
  return contentRepository.fetchMovieListing(language);
};
