import {
  HttpClient,
  HttpInstanceParams,
  ErrorResponse
} from '@jsCore/src/types/clients';
import type { AxiosInstance, AxiosResponse, AxiosError } from 'axios';
import axios from 'axios';
import qs from 'query-string';
import { axiosETAGCache } from 'axios-etag-cache';

class AxiosHttpClient implements HttpClient {
  private getInstance(params?: HttpInstanceParams): AxiosInstance {
    return axios.create({
      timeout: 60000,
      withCredentials:
        params && params.credentials !== undefined ? params.credentials : true,
      headers: {
        'Content-Type':
          params && params.Content_Type
            ? params.Content_Type
            : 'application/json',
        ...(params && params.token ? { Authorization: params.token } : {})
      }
    });
  }

  private generateSuccessResponse(response: AxiosResponse) {
    return {
      success: true,
      statusCode: response.status,
      data: response.data
    };
  }

  private generateErrorResponse(error: AxiosError): ErrorResponse {
    return {
      success: false,
      statusCode: error.response?.status || 400,
      error: error.response?.data || 'Internal Server Error'
    };
  }

  get(path: string, query?: object | null, params?: HttpInstanceParams) {
    const getPath =
      query && Object.keys(query).length
        ? `${path}?${qs.stringify(query)}`
        : path;

    const axiosWithETAGCache = axiosETAGCache(this.getInstance(params));
    return axiosWithETAGCache
      .get(getPath)
      .then((response) => this.generateSuccessResponse(response))
      .catch((error: AxiosError) => {
        throw this.generateErrorResponse(error);
      });
  }

  post(path: string, body: object | string, params?: HttpInstanceParams) {
    return this.getInstance(params)
      .post(path, body)
      .then((response) => this.generateSuccessResponse(response))
      .catch((error: AxiosError) => {
        throw this.generateErrorResponse(error);
      });
  }

  put(path: string, body: object, params?: HttpInstanceParams) {
    return this.getInstance(params)
      .put(path, body)
      .then((response) => this.generateSuccessResponse(response))
      .catch((error) => {
        throw this.generateErrorResponse(error);
      });
  }

  patch(path: string, body: object, params?: HttpInstanceParams) {
    return this.getInstance(params)
      .patch(path, body)
      .then((response) => this.generateSuccessResponse(response))
      .catch((error) => {
        throw this.generateErrorResponse(error);
      });
  }

  delete(path: string, params?: HttpInstanceParams) {
    return this.getInstance(params)
      .delete(path)
      .then((response) => this.generateSuccessResponse(response))
      .catch((error) => {
        throw this.generateErrorResponse(error);
      });
  }
}

export default AxiosHttpClient;
