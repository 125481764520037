import { GetSignedUrlInput } from '@jsCore/src/types/providers';
import { GreenFieldRepository } from '@jsCore/src/types/repositories';

export const getSignedUrlUseCase = async (
  greenFieldRepository: GreenFieldRepository,
  inputs: GetSignedUrlInput
) => {
  const token = greenFieldRepository.getLeadGeneratedToken();
  if (token === '') return Promise.reject('Token is unavailable');
  return await greenFieldRepository.getSignedUrl(inputs, token);
};
