import { ContactUsInputs } from '@jsCore/src/types/models/contactus';
import {
  ContentRepository,
  PurchaseRepository
} from '@jsCore/src/types/repositories';
import { B40Status, B40Token } from '@jsCore/src/types/providers';

export const submitLeadUseCase = async (
  purchaseRepository: PurchaseRepository,
  inputs: ContactUsInputs
): Promise<boolean> => {
  const response = await purchaseRepository.submitLead(inputs);
  return response.includes('thankyoupage');
};

export const getB40TokenUseCase = async (
  contentRepository: ContentRepository
): Promise<B40Token> => {
  return await contentRepository.fetchB40Token();
};

export const getB40StatusUseCase = async (
  contentRepository: ContentRepository,
  inputs: { identityCard: string; token: string; type: string }
): Promise<B40Status> => {
  return await contentRepository.fetchB40Status(
    inputs.identityCard,
    inputs.token,
    inputs.type
  );
};
