import { NjoiStoreArticleDetail } from '@jsCore/src/types/providers';
import {
  ConfigRepository,
  ContentRepository
} from '@jsCore/src/types/repositories';

export const getNjoiStoreArticleDetailUseCase = (
  configRepository: ConfigRepository,
  slug: string,
  contentRepository: ContentRepository
): Promise<NjoiStoreArticleDetail> => {
  const language = configRepository.getLanguage();
  return contentRepository.fetchNjoiStoreArticleDetail(language, slug);
};
