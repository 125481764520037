import { CheckEntitledAstroFibreResponse } from '@jsCore/src/types/providers';
import { GreenFieldRepository } from '@jsCore/src/types/repositories';

export const validateEntitledAstroFibreUseCase = async (
  greenFieldRepository: GreenFieldRepository,
  njoiToken: string
): Promise<CheckEntitledAstroFibreResponse> => {
  if (njoiToken === '') return Promise.reject('NJOI Token is unavailable');
  return await greenFieldRepository.getEntitledAstroFibreStatus(njoiToken);
};
